<template>
    <v-stage
        ref="stage"
        :config="configKonva"
        @mousedown="handleStageMouseDown"
        @touchstart="handleStageMouseDown"
    >
        <v-layer>
            <v-image :config="{
              x: 0,
              y: 0,
              width: configKonva.width,
              height: configKonva.height,
              image: (previewMode === 'background') ? backgroundImage : sampleImage,
            }" />
            <v-transformer ref="transformer" />
        </v-layer>

        <v-layer>

            <invitation-text
                v-if="template.values.main_text.active"
                :config="template.values.main_text"
                :text="invitation.main_text"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.secondary_text.active"
                :config="template.values.secondary_text"
                :text="invitation.secondary_text"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.name.active"
                :config="template.values.name"
                :text="invitation.name"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.age.active"
                :config="template.values.age"
                :text="modifyAge(invitation.age)"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.party_date.active"
                :config="template.values.party_date"
                :text="invitation.party_date"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.party_hour.active"
                :config="template.values.party_hour"
                :text="invitation.party_hour"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.party_date_hour.active"
                :config="template.values.party_date_hour"
                :text="removeNullConcated(`${invitation.party_date} às ${invitation.party_hour}`)"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.venue_name.active"
                :config="template.values.venue_name"
                :text="invitation.venue_name"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.address_street_number.active && (invitation.address_street || invitation.address_number)"
                :config="template.values.address_street_number"
                :text="removeNullConcated(`${invitation.address_street}, ${invitation.address_number}`)"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.address_complement.active"
                :config="template.values.address_complement"
                :text="invitation.address_complement"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.address_district.active"
                :config="template.values.address_district"
                :text="invitation.address_district"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.address_street_number_complement.active && (invitation.address_street || invitation.address_number || invitation.address_complement)"
                :config="template.values.address_street_number_complement"
                :text="removeNullConcated(`${invitation.address_street}, ${invitation.address_number} - ${invitation.address_complement}`)"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <invitation-text
                v-if="template.values.address_street_number_complement_district.active && (invitation.address_street || invitation.address_number || invitation.address_complement || invitation.address_district)"
                :config="template.values.address_street_number_complement_district"
                :text="removeNullConcated(`${invitation.address_street}, ${invitation.address_number} - ${invitation.address_complement} - ${invitation.address_district}`)"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />


            <invitation-text
                v-if="template.values.address_city_state.active && (invitation.address_city || invitation.address_state)"
                :config="template.values.address_city_state"
                :text="removeNullConcated(`${invitation.address_city} / ${invitation.address_state}`)"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
                :edit-mode="editMode"
            />

            <component
                v-if="template.widget.isActive"
                :is="template.widget.name"
                :config="template.widget"
                :date="invitation.party_date"
                :hour="invitation.party_hour"
                @transformend="handleTransformEndWidget"
                @dragend="handleDragEndWidget"
                @updated="widgetUpdated"
                :edit-mode="editMode"
            />


            <v-image v-if="watermark" :config="{
              x: 0,
              y: 0,
              width: configKonva.width,
              height: configKonva.height,
              image: watermarkImage,
            }" />
        </v-layer>

    </v-stage>
</template>

<script>

// https://storage.festow.com.br/templates/104bb3cf-ad13-4049-bb29-3d6c75085e73/c9b3f88f-8a18-477e-8238-ccfc93deb828.jpg

import InvitationText from './InvitationTextKonva.vue'
import VueKonva from 'vue-konva';

export default {
    components: {
        InvitationText
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        invitation: {
            type: Object,
            default: {}
        },
        template: {
            type: Object
        },
        watermark: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            configKonva: {
                width: 600,
                height: 900,
            },
            nameFontSize: 90,
            backgroundImage: null,
            sampleImage: null,
            watermarkImage: null,
            selectedShapeName: '',
            previewMode: 'background'
        };
    },
    created() {
        const backgroundImage = new window.Image();
        backgroundImage.src = this.template.download_image_path;
        backgroundImage.crossOrigin = 'Anonymous';
        backgroundImage.onload = () => {
            // set backgroundImage only when it is loaded
            this.backgroundImage = backgroundImage;
        };

        if (this.editMode) {
            const sampleImage = new window.Image();
            sampleImage.src = this.template.sample_image_path;
            sampleImage.crossOrigin = 'Anonymous';
            sampleImage.onload = () => {
                // set sampleImage only when it is loaded
                this.sampleImage = sampleImage;
            };
        }

        const watermarkImage = new window.Image();
        watermarkImage.src = "/imgs/watermark.png";
        watermarkImage.onload = () => {
            // set image only when it is loaded
            this.watermarkImage = watermarkImage;
        };
    },
    mounted() {
        // console.log(this.$refs.stage.getStage());
        // this.fixFontSize()
        // console.log(this.invitation)

        // this.template.values.main_text.text = this.invitation.main_text
        // this.invitation.name = 'Amanda'
    },
    methods: {
        handleDragEnd(e) {
            let parent = e.target.parent
            let text = e.target

            let newX = parent.attrs.x + text.x()
            let newY = parent.attrs.y + text.y()

            text.setAttrs({
                y: 0,
                x: 0
            });

            this.$emit('dragend', {
                x: newX,
                y: newY
            })
        },
        handleDragEndWidget(e) {
            let text = e.target

            text.setAttrs({
                y: text.y(),
                x: text.x()
            });

            this.$emit('dragend', {
                x: text.x(),
                y: text.y()
            })
        },
        handleTransformEndWidget(e) {
            const transformerNode = this.$refs.transformer.getNode();
            const stage = transformerNode.getStage();

            let selectedShapeName = e.target.attrs.name;
            const text = stage.findOne('.' + selectedShapeName);

            text.setAttrs({
                // scale: text.scale(),
            });

            // this.invitation[name].height = newHeight

            this.$emit('transformend', {
                scale: text.scale(),
            })

        },
        handleTransformEnd(e) {
            const transformerNode = this.$refs.transformer.getNode();
            const stage = transformerNode.getStage();

            // //   const { selectedShapeName } = this;
            let selectedShapeName = e.target.attrs.name;
            const text = stage.findOne('.' + selectedShapeName);

            let parent = text.parent.getStage()

            let newHeight = text.height() * text.scaleY()
            let newWidth = text.width() * text.scaleX()

            text.setAttrs({
                width: newWidth,
                height: newHeight,
                scaleX: 1,
                scaleY: 1,
                y: 0,
                x: 0
            });

            this.$emit('transformend', {
                width: newWidth,
                height: newHeight
            })

        },
        handleStageMouseDown(e) {

            if (!this.editMode) {
                return false;
            }

            // clicked on stage - clear selection
            if (e.target === e.target.getStage()) {
                this.selectedShapeName = '';
                this.updateTransformer();
                return;
            }

            // clicked on transformer - do nothing
            const clickedOnTransformer = e.target.getParent().className === 'Transformer';
            if (clickedOnTransformer) {
                return;
            }

            let name = e.target.name();

            let clickedOnWidget = e.target.parent.attrs.name && e.target.parent.attrs.name.indexOf('widget') !== -1
            if (clickedOnWidget) {
                name = e.target.parent.attrs.name
            }

            if (name) {
                this.selectedShapeName = name;
                this.$emit('select', name)
            } else {
                this.selectedShapeName = '';
                this.$emit('unselect', name)
            }

            if (clickedOnWidget) {
                this.updateTransformer(false);
                return;
            }
            this.updateTransformer(true);
        },
        getDataUrl(pixelRatio = 2) {
            let stage = this.$refs.stage.getStage();
            return stage.toDataURL({
                pixelRatio // or other value you need
            });
        },
        modifyAge(age) {
            if (!age) {
                return ''
            }

            if (!this.isOnlyNumbers(age.trim())) {
                return age;
            }

            let numericAge = parseInt(age)

            if (numericAge === 1) {
                return '1 ano';
            }
            return `${numericAge} anos`;
        },
        isOnlyNumbers(string) {
            return /^[0-9]+$/.test(string);
        },
        removeNullConcated(str) {
            return str.replaceAll('null', '');
        },
        save() {
            let dataURL = this.getDataUrl();
            this.downloadURI(dataURL, 'Invitation.png');
        },
        updateTransformer(resizeEnabled) {
            // here we need to manually attach or detach Transformer node
            const transformerNode = this.$refs.transformer.getNode();
            const stage = transformerNode.getStage();
            const { selectedShapeName } = this;

            transformerNode.resizeEnabled(resizeEnabled)

            const selectedNode = stage.findOne('.' + selectedShapeName);
            // do nothing if selected node is already attached
            if (selectedNode === transformerNode.node()) {
                return;
            }

            if (selectedNode) {
                // attach to another node
                transformerNode.nodes([selectedNode]);
                transformerNode.setRotationSnaps([0, 90, 180, 270]);
            } else {
                // remove transformer
                transformerNode.nodes([]);
            }
        },
        downloadURI(uri, name) {
            var link = document.createElement('a');
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // delete link;
        },
        togglePreviewMode() {
            if (this.previewMode === 'background') {
                this.previewMode = 'sample'
            } else {
                this.previewMode = 'background'
            }
        },
        widgetUpdated(widgetConfig) {
            this.$emit('widgetupdated', widgetConfig)
        },
    },
};

</script>
