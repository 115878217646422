import FontFaceObserver from "fontfaceobserver";
import {isNumber} from "lodash";

const WidgetMixin = {
    data() {
        return {
            xAdjust: 0,
        }
    },
    mounted() {
        // https://fontfaceobserver.com/
        if (this.config.elements[0] && this.$refs.element_0) {
            this.fixFontAfterLoad(this.config.elements[0], this.$refs.element_0.getStage())
        }
        if (this.config.elements[1] && this.$refs.element_1) {
            this.fixFontAfterLoad(this.config.elements[1], this.$refs.element_1.getStage())
        }
        if (this.config.elements[2] && this.$refs.element_2) {
            this.fixFontAfterLoad(this.config.elements[2], this.$refs.element_2.getStage())
        }

        setTimeout(() => {
            this.adjustPosition();
        },300)
        setTimeout(() => {
            this.adjustPosition();
        },1000)
        setTimeout(() => {
            this.adjustPosition();
        },2000)

    },
    computed: {
        x() {
            return this.config.x + this.xAdjust
        },
        isDateValid() {
            return this.datejs.isValid()
        }
    },
    methods: {
        fixFontAfterLoad(element, node) {
            var font = new FontFaceObserver(element.style.font.name, {
                weight: element.style.fontStyle.weight,
                style: 'italic'
            });
            font.load().then(() => {
                let text = node;
                text.fontFamily('')
                text.fontFamily(element.style.font.name);
            });
        },
        getSize() {
            let group = this.$refs.group.getStage();
            let {width, height} = (group.getClientRect())
            return {width, height}
        },
        handleTransformEnd(e) {
            this.$emit('transformend', e)
        },
        handleDragEnd(e) {
            this.$emit('dragend', e)
        },
        forceNumber(number, fallback) {
            let floatNumber = parseFloat(number)
            if (isNumber(floatNumber)) {
                return floatNumber
            }
            return fallback;
        },
        adjustPosition() {
            let group = this.$refs.group.getStage()

            if (this.config.horizontal_align === 'center') {
                this.xAdjust = (this.config.width - group.getClientRect().width) / 2;
            }

            if (this.config.horizontal_align === 'right') {
                this.xAdjust = (this.config.width - group.getClientRect().width);
            }

        },
        watchHandler() {
            this.$nextTick(() => {

                if (typeof this.calculateElementsPositions === "function") {
                    this.calculateElementsPositions()
                }

                if (!this.editMode) {
                    this.adjustPosition()
                }

                this.$emit('updated', { ...this.textConfig, ...this.getSize()})
            });
        }
    },
    watch: {
        config: {
            handler: function (newValue, oldValue) {
                this.watchHandler(newValue, oldValue)
            },
            deep: true
        },
        date: {
            handler: function (newValue, oldValue) {
                this.watchHandler(newValue, oldValue)
            },
            deep: true,
            immediate: true
        },
        hour: {
            handler: function (newValue, oldValue) {
                this.watchHandler(newValue, oldValue)
            },
            deep: true
        }
    }
}

export default WidgetMixin;
